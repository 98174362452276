<template>
  <div id="contract-launch-discount-form">
    <p v-if="description">
      {{ description }}
    </p>

    <div class="mb-2">
      <b-form-group
        :label="percentageLabel"
        label-for="discountPercentage"
        :description="percentageDescription"
      >
        <b-input-group append="%" class="input-group-merge">
          <b-form-input
            id="discountPercentage"
            v-model="discountPercentage"
            placeholder="0"
            :formatter="percentageInputFormatter"
          />
        </b-input-group>
      </b-form-group>

      <b-form-group
        :label="daysLabel"
        label-for="discountDays"
        :description="daysDescription"
      >
        <b-input-group append="dies" class="input-group-merge">
          <b-form-input
            id="discountDays"
            v-model="discountDays"
            placeholder="0"
            :formatter="daysInputFormatter"
          />
        </b-input-group>
      </b-form-group>
    </div>

    <div class="d-flex justify-content-end">
      <b-button variant="secondary" class="mr-50" @click="cancel">
        {{ $t("Cancel·la") }}
      </b-button>
      <b-button variant="danger" class="mr-50" @click="remove">
        {{ $t("Elimina") }}
      </b-button>
      <b-button variant="primary" @click="save">
        {{ $t("Guarda") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { BButton, BFormInput, BFormGroup, BInputGroup } from "bootstrap-vue";

export default {
  components: {
    BFormInput,
    BFormGroup,
    BInputGroup,
    BButton,
  },
  props: {
    description: {
      type: String,
      default: null,
    },
    percentage: {
      type: Number,
      default: null,
    },
    percentageLabel: {
      type: String,
      default: null,
    },
    percentageDescription: {
      type: String,
      default: null,
    },
    days: {
      type: Number,
      default: null,
    },
    daysLabel: {
      type: String,
      default: null,
    },
    daysDescription: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      discountPercentage: null,
      discountDays: null,
    };
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
  },
  created() {
    this.discountPercentage = this.percentage || null;
    this.discountDays = this.days || null;
  },
  methods: {
    percentageInputFormatter(value) {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length > 2 && numericValue > 100)
        return numericValue.slice(0, -1);
      return numericValue;
    },
    daysInputFormatter(value) {
      const numericValue = value.replace(/[^0-9]/g, "");
      if (numericValue.length > 2 && numericValue > 365)
        return numericValue.slice(0, -1);
      return numericValue;
    },
    save() {
      this.$emit("save", {
        percentage: parseInt(this.discountPercentage) || null,
        days: parseInt(this.discountDays) || null,
      });
    },
    remove() {
      this.$emit("save", {
        percentage: null,
        days: null,
      });
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
