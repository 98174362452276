<template>
  <div>
    <div
      class="d-flex justify-content-between py-1"
      :class="{ 'border-bottom': borderBottom, 'border-top': borderTop }"
    >
      <div>
        <h5 :class="{ 'mb-0': !subtitle }">
          {{ title }}
        </h5>
        <p v-if="subtitle" class="mb-0">
          {{ subtitle }}
        </p>
      </div>
      <b-button :variant="buttonVariant" @click="onClick">
        {{ buttonText }}
      </b-button>
    </div>
  </div>
</template>
<script>
import { BButton } from "bootstrap-vue";

export default {
  components: {
    BButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    buttonVariant: {
      type: String,
      default: "primary",
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
</script>
