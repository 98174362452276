<template>
  <div>
    <b-tabs>
      <!-- Basic Information -->
      <b-tab title="Informació Bàsica">
        <details-card @updated="onDetailsUpdated" />
        <billing-card />
        <checkin-checkout-card />
        <guests-info-card />
        <co-owners-card
          @coowner-added="fetchContract"
          @coowner-deleted="fetchContract"
        />
        <danger-card />
      </b-tab>

      <!-- Services and Conditions -->
      <b-tab title="Serveis i Condicions">
        <services-card />
        <providers-card />
        <posting-conditions-card />
        <discounts-card />
        <rules-card />
      </b-tab>

      <!-- Rates -->
      <b-tab title="Tarifes">
        <rates-card />
      </b-tab>

      <!-- Bookings -->
      <b-tab title="Reserves">
        <bookings-card />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import DetailsCard from "@/views/contracts/contract/components/DetailsCard.vue";
import BillingCard from "@/views/contracts/contract/components/BillingCard.vue";
import CheckinCheckoutCard from "@/views/contracts/contract/components/CheckinCheckoutCard.vue";
import GuestsInfoCard from "@/views/contracts/contract/components/GuestsInfoCard.vue";
import CoOwnersCard from "@/views/contracts/contract/components/CoOwnersCard.vue";
import DangerCard from "@/views/contracts/contract/components/DangerCard.vue";
import RatesCard from "@/views/contracts/contract/components/RatesCard.vue";
import DiscountsCard from "@/views/contracts/contract/components/DiscountsCard.vue";
import ServicesCard from "@/views/contracts/contract/components/ServicesCard.vue";
import RulesCard from "@/views/contracts/contract/components/RulesCard.vue";
import BookingsCard from "@/views/contracts/contract/components/BookingsCard.vue";
import ProvidersCard from "@/views/contracts/contract/components/ProvidersCard.vue";
import PostingConditionsCard from "@/views/contracts/contract/components/PostingConditionsCard.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BTabs,
    BTab,
    DetailsCard,
    BillingCard,
    CheckinCheckoutCard,
    GuestsInfoCard,
    CoOwnersCard,
    DangerCard,
    RatesCard,
    ServicesCard,
    DiscountsCard,
    RulesCard,
    BookingsCard,
    ProvidersCard,
    PostingConditionsCard,
  },
  computed: {
    loadingOwners() {
      return this.$store.getters["owners/loading"];
    },
    owners() {
      return this.$store.getters["owners/owners"];
    },
    loadingServices() {
      return this.$store.getters["services/loading"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    loadingProviders() {
      return this.$store.getters["providers/loading"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
  },
  created() {
    this.fetchContract();

    if (!this.owners?.length && !this.loadingOwners) {
      this.fetchOwners();
    }

    if (!this.services?.length && !this.loadingServices) {
      this.fetchServices();
    }

    if (!this.providers?.length && !this.loadingProviders) {
      this.fetchProviders();
    }
  },
  methods: {
    fetchContract() {
      const { contractUuid } = this.$route.params;

      if (!contractUuid) {
        notifyError(
          this.$t("errors.fetchContract.noUuidTitle"),
          this.$t("errors.fetchContract.noUuidDescription")
        );
        return;
      }

      this.$store
        .dispatch("contract/fetchContract", contractUuid)
        .then(() => {
          this.$store.dispatch("contract/fetchServices", contractUuid);
          this.$store.dispatch("contract/fetchProviders", contractUuid);
          this.$store.dispatch("contract/fetchOwnerRates", contractUuid);
          this.$store.dispatch("contract/fetchBookings", contractUuid);
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchContract.title"),
            this.$t("errors.fetchContract.description")
          )
        );
    },
    fetchOwners() {
      this.$store
        .dispatch("owners/fetchOwners")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchOwners.title"),
            this.$t("errors.fetchOwners.description")
          )
        );
    },
    fetchServices() {
      this.$store
        .dispatch("services/fetchServices", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchServices.title"),
            this.$t("errors.fetchServices.description")
          )
        );
    },
    fetchProviders() {
      this.$store
        .dispatch("providers/fetchProviders", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchProviders.title"),
            this.$t("errors.fetchProviders.description")
          )
        );
    },
    onDetailsUpdated() {
      this.$store.dispatch("contract/fetchOwnerRates", this.contract.uuid);
    },
  },
};
</script>
