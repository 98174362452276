<template>
  <div
    id="contract-rates-form"
    class="d-flex flex-column justify-content-between"
  >
    <b-form>
      <b-row>
        <!-- START DATE -->
        <b-col cols="6">
          <b-form-group label="Data d'inici" label-for="startDate">
            <b-form-datepicker
              id="startDate"
              v-model="startDate"
              placeholder="Inici"
              :locale="$i18n.locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-form-group>
        </b-col>
        <!-- END DATE -->
        <b-col cols="6">
          <b-form-group label="Data de fi" label-for="endDate">
            <b-form-datepicker
              id="endDate"
              ref="endDateDatepicker"
              v-model="endDate"
              placeholder="Fi"
              :min="startDate"
              :locale="$i18n.locale"
              :start-weekday="1"
              :show-decade-nav="false"
              :date-format-options="{
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }"
              hide-header
            />
          </b-form-group>
        </b-col>
        <!-- OWNER PRICE -->
        <b-col cols="6">
          <b-form-group label="Import propietari" label-for="price">
            <b-input-group class="input-group-merge" append="€">
              <b-form-input
                id="price"
                v-model="ownerPrice"
                type="number"
                number
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- BOOKING POLICY -->
        <b-col cols="6">
          <b-form-group label="Política de reserva" label-for="bookingPolicy">
            <v-select
              id="bookingPolicy"
              v-model="bookingPolicy"
              :options="bookingPoliciesOptions"
              :reduce="(option) => option.value"
              :loading="loadingBookingPolicies"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- BUTTONS -->
    <b-row
      class="mb-1 d-flex justify-content-between justify-content-sm-end mt-2"
    >
      <b-col cols="6" sm="auto">
        <b-button block variant="light"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="save"> Guarda </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BForm,
  BFormInput,
  BRow,
  BCol,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BForm,
    BRow,
    BCol,
    vSelect,
    BFormInput,
    BFormDatepicker,
    BInputGroup,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      startDate: null,
      endDate: null,
      ownerPrice: null,
      bookingPolicy: null,
    };
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
    accommodation() {
      return this.contract?.accommodation || null;
    },
    loadingBookingPolicies() {
      return this.$store.getters["bookingPolicies/loading"];
    },
    bookingPoliciesOptions() {
      return this.$store.getters["bookingPolicies/policies"].map(
        (bookingPolicy) => ({
          label: bookingPolicy.name,
          value: bookingPolicy["@id"],
        })
      );
    },
  },
  watch: {
    startDate(startDate) {
      if (startDate && (!this.endDate || this.endDate < startDate)) {
        this.endDate = this.$moment(startDate)
          .endOf("month")
          .format("YYYY-MM-DD");
      }
    },
  },
  methods: {
    save() {
      this.$store.dispatch("app/setLoading", true);
      setTimeout(() => {
        this.$store
          .dispatch("contract/addOwnerRates", {
            accommodation: this.accommodation["@id"],
            startDate: this.startDate,
            endDate: this.endDate,
            ownerPrice: Math.round(this.ownerPrice * 100),
            bookingPolicy: this.bookingPolicy,
          })
          .then(() => this.$emit("owner-rates-added"))
          .catch(() => {
            this.$emit("add-owner-rates-error");
            // TODO: Log error in Sentry? Only if is not logged in the store
          })
          .finally(() => this.$store.dispatch("app/setLoading", false));
      }, 100);
    },
  },
};
</script>

<style scoped>
#contract-rates-form {
  min-height: 430px;
}
</style>
