<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Facturació i pagaments">
      <b-row>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Tipus de facturació</h5>
          <v-select
            v-if="editMode"
            id="billingType"
            v-model="billingType"
            placeholder="Selecciona un tipus de gestió"
            :options="billingTypeOptions"
            :reduce="(option) => option.value"
            :clearable="false"
          />
          <span v-else>{{ contractBillingType }}</span>
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>% IVA propietari</h5>
          <b-input-group v-if="editMode" append="%" class="input-group-merge">
            <b-form-input v-model="ownerVatPercentage" number />
          </b-input-group>
          <span v-else>{{ contractOwnerVatPercentage }}</span>
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>% IRPF propietari</h5>
          <b-input-group v-if="editMode" append="%" class="input-group-merge">
            <b-form-input v-model="ownerIrpfPercentage" number />
          </b-input-group>
          <span v-else>{{ contractOwnerIrpfPercentage }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>IBAN</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="iban" />
          </b-input-group>
          <span v-else>{{ contractIban }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Preu garantia (opcional)</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="fixedSeasonPrice" />
          </b-input-group>
          <span v-else>{{ contractFixedSeasonPrice }}</span>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { billingTypeOptions } from "@/utils/select-options";
import { getBillingTypeName, notifyError } from "@/utils/methods";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton,
    BFormInput,
    BInputGroup,
  },
  data() {
    return {
      editMode: false,
      billingType: null,
      ownerVatPercentage: null,
      ownerIrpfPercentage: null,
      iban: null,
      fixedSeasonPrice: null,
      billingTypeOptions,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractBillingType() {
      return (
        getBillingTypeName(this.contract?.billingType) ||
        this.$t("No disponible")
      );
    },
    contractOwnerVatPercentage() {
      if (this.contract?.ownerVatPercentage == null)
        return this.$t("No disponible");
      return `${this.contract?.ownerVatPercentage} %`;
    },
    contractOwnerIrpfPercentage() {
      if (this.contract?.ownerIrpfPercentage == null)
        return this.$t("No disponible");
      return `${this.contract?.ownerIrpfPercentage} %`;
    },
    contractIban() {
      return this.contract?.iban || null;
    },
    contractFixedSeasonPrice() {
      if (!this.contract?.fixedSeasonPrice) {
        return this.$t("No definit");
      }
      return formatCurrency(this.contract.fixedSeasonPrice);
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.billingType = this.contract?.billingType || null;
      this.ownerVatPercentage = this.contract?.ownerVatPercentage || null;
      this.ownerIrpfPercentage = this.contract?.ownerIrpfPercentage || null;
      this.iban = this.contract?.iban || null;
      this.fixedSeasonPrice =
        (this.contract?.fixedSeasonPrice || 0) / 100 || null;
    },
    resetInputs() {
      this.billingType = null;
      this.ownerVatPercentage = null;
      this.ownerIrpfPercentage = null;
      this.iban = null;
      this.fixedSeasonPrice = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          billingType: this.billingType,
          ownerVatPercentage: this.ownerVatPercentage,
          ownerIrpfPercentage: this.ownerIrpfPercentage,
          iban: this.iban,
          fixedSeasonPrice: this.fixedSeasonPrice * 100,
        })
        .then(() => {
          this.editMode = false;
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
