<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Normes de la casa">
      <b-row>
        <b-col cols="12">
          <!-- PETS ALLOWED -->
          <contract-item-yes-no
            v-model="petsAllowed"
            title="Animals de companyia permesos"
            subtitle="Recorda que els animals d'assistència seran sempre acceptats"
            border-bottom
            :read-only="!editMode"
          />
          <!-- ONLY ADULTS -->
          <contract-item-yes-no
            v-model="onlyAdults"
            title="Només adults permesos"
            border-bottom
            :read-only="!editMode"
          />
          <!-- YOUTH GROUPS ALLOWED -->
          <contract-item-yes-no
            v-model="youthGroupsAllowed"
            title="Grups de joves permesos"
            subtitle="Es consideren grups de joves els menors de 30 anys"
            border-bottom
            :read-only="!editMode"
          />
          <!-- EVENTS ALLOWED -->
          <contract-item-yes-no
            v-model="eventsAllowed"
            title="Events permesos"
            border-bottom
            :read-only="!editMode"
          />
          <!-- SMOKING ALLOWED -->
          <contract-item-yes-no
            v-model="smokingAllowed"
            title="Apte per a fumadors"
            :read-only="!editMode"
          />
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import ContractItemYesNo from "@/views/contracts/contract/components/ContractItemYesNo.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    ContractItemYesNo,
    BButton,
  },
  data() {
    return {
      editMode: false,
      petsAllowed: false,
      onlyAdults: false,
      youthGroupsAllowed: false,
      eventsAllowed: false,
      smokingAllowed: false,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
  },
  watch: {
    contract: {
      deep: true,
      handler() {
        this.initInputs();
      },
    },
  },
  created() {
    this.initInputs();
  },
  methods: {
    initInputs() {
      this.petsAllowed = this.contract?.petsAllowed || false;
      this.onlyAdults = this.contract?.onlyAdults || false;
      this.youthGroupsAllowed = this.contract?.youthGroupsAllowed || false;
      this.eventsAllowed = this.contract?.eventsAllowed || false;
      this.smokingAllowed = this.contract?.smokingAllowed || false;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          petsAllowed: this.petsAllowed,
          onlyAdults: this.onlyAdults,
          youthGroupsAllowed: this.youthGroupsAllowed,
          eventsAllowed: this.eventsAllowed,
          smokingAllowed: this.smokingAllowed,
        })
        .then(() => {
          this.editMode = false;
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
