<template>
  <div id="contract-service-form" class="py-1">
    <!-- SERVICE -->
    <b-row class="mb-2">
      <b-col cols="12">
        <fr-form-select
          label="Servei"
          :options="serviceOptions"
          v-model="service"
        />
      </b-col>
    </b-row>

    <!-- CHARGABLE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <fr-form-switch label="És obligatori?" v-model="mandatory" />
      </b-col>
      <b-col cols="12" sm="6">
        <fr-form-switch
          label="Cobrem nosaltres?"
          v-model="chargable"
          :disabled="paymentTime === 'PROVIDER'"
        />
      </b-col>
    </b-row>

    <!-- PAYMENT FREQUENCY & TIME -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <fr-form-select
          label="Freqüència de pagament"
          v-model="paymentFrequency"
          :options="paymentFrequencyOptions"
        />
      </b-col>
      <b-col cols="12" sm="6">
        <fr-form-select
          label="Moment de pagament"
          v-model="paymentTime"
          :options="paymentTimeOptions"
        />
      </b-col>
    </b-row>

    <!-- PAYMENT METHODS -->
    <b-row class="mb-2">
      <b-col cols="12">
        <fr-form-checkboxes
          label="Mètodes de pagament"
          v-model="paymentMethods"
          :options="paymentMethodOptions"
        />
      </b-col>
    </b-row>

    <!-- INVOICE HOLDER & VAT PERCENTAGE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <fr-form-select
          label="% I.V.A."
          v-model="vatPercentage"
          :options="vatPercentageOptions"
        />
      </b-col>
      <b-col cols="12" sm="6">
        <fr-form-select v-model="invoiceHolder" :options="invoiceHolderOptions">
          Emissor de la factura <small>(opcional)</small>
        </fr-form-select>
      </b-col>
    </b-row>

    <!-- PVP & COST PRICE -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <fr-form-input v-model="pvp" append="€" type="number">
          PVP <small>(opcional)</small>
        </fr-form-input>
      </b-col>
      <b-col cols="12" sm="6">
        <fr-form-input v-model="costPrice" append="€" type="number">
          Preu propietari <small>(opcional)</small>
        </fr-form-input>
      </b-col>
    </b-row>

    <!-- COMISSION & AMOUNT -->
    <b-row class="mb-2">
      <b-col cols="12" sm="6" class="mb-1 mb-sm-0">
        <fr-form-input v-model="commissionPercentage" append="%" type="number">
          Comissió <small>(opcional)</small>
        </fr-form-input>
      </b-col>
      <b-col cols="12" sm="6">
        <fr-form-spinbutton v-model="amount">
          Serveis disponibles <small>(opcional)</small>
        </fr-form-spinbutton>
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button block variant="primary" @click="saveContractService">
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import {
  FrFormCheckboxes,
  FrFormInput,
  FrFormSelect,
  FrFormSpinbutton,
  FrFormSwitch,
} from "@/@foravila-core/components/fr-form";
import {
  paymentFrequencyOptions,
  paymentTimeOptions,
  paymentMethodOptions,
  invoiceHolderOptions,
  vatPercentageOptions,
} from "@/utils/select-options";
import { getServiceName, getInitialServiceProps } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    FrFormSelect,
    FrFormSwitch,
    FrFormInput,
    FrFormSpinbutton,
    FrFormCheckboxes,
  },
  props: {
    contractService: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      service: null,
      pvp: null,
      costPrice: null,
      amount: null,
      mandatory: false,
      chargable: false,
      paymentFrequency: null,
      paymentTime: null,
      paymentMethods: ["CREDIT_CARD", "BANK_TRANSFER", "BIZUM"],
      commissionPercentage: null,
      invoiceHolder: null,
      vatPercentage: null,
      paymentFrequencyOptions,
      paymentTimeOptions,
      paymentMethodOptions,
      invoiceHolderOptions,
      vatPercentageOptions,
    };
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
    services() {
      return this.$store.getters["services/services"];
    },
    serviceOptions() {
      if (!this.services.length) return [];

      const options = [...this.services];

      options.sort((a, b) => {
        if (a.code === "OTHER") return 1;
        if (b.code === "OTHER") return -1;
        return (a.description || "").localeCompare(b.description || "");
      });

      return options.map((service) => ({
        label: getServiceName(service, this.$i18n.locale),
        value: service["@id"],
      }));
    },
  },
  watch: {
    paymentTime(value) {
      if (value === "PROVIDER") {
        this.chargable = false;
      }
    },
    invoiceHolder(value) {
      if (!value) {
        this.invoiceHolder = null;
      }
    },
    service(serviceId) {
      // If we receive a contractService as a prop, it means that we
      // are editing a service, so there's no need to init the form
      // and we can stop the execution.
      if (this.contractService) {
        return;
      }

      const foundService = this.services.find(
        (service) => service["@id"] === serviceId
      );

      if (foundService) {
        this.initService(foundService.code);
      }
    },
  },
  created() {
    if (this.contractService) {
      this.initForm();
    }
  },
  methods: {
    initForm() {
      if (!this.contractService) return;
      this.service = this.contractService.service["@id"];
      this.pvp = this.contractService.price / 100;
      this.costPrice = this.contractService.costPrice / 100;
      this.amount = this.contractService.amount;
      this.mandatory = this.contractService.mandatory || false;
      this.paymentFrequency = this.contractService.paymentFrequency;
      this.paymentTime = this.contractService.paymentTime;
      this.paymentMethods = this.contractService.paymentMethods;
      this.chargable = this.contractService.chargable || false;
      this.commissionPercentage = this.contractService.commissionPercentage;
      this.invoiceHolder = this.contractService.invoiceHolder;
      this.vatPercentage = this.contractService.vatPercentage;
    },
    initService(serviceCode) {
      if (!serviceCode) {
        return;
      }

      const {
        mandatory,
        chargable,
        paymentFrequency,
        paymentTime,
        paymentMethods,
        pvp,
        costPrice,
        commissionPercentage,
        amount,
        invoiceHolder,
        vatPercentage,
      } = getInitialServiceProps(serviceCode) || {};

      this.mandatory = mandatory;
      this.chargable = chargable;
      this.paymentFrequency = paymentFrequency;
      this.paymentTime = paymentTime;
      this.paymentMethods = paymentMethods;
      this.pvp = pvp;
      this.costPrice = costPrice;
      this.commissionPercentage = commissionPercentage;
      this.amount = amount;
      this.invoiceHolder = invoiceHolder;
      this.vatPercentage = vatPercentage;
    },
    resetForm() {
      this.service = null;
      this.pvp = null;
      this.costPrice = null;
      this.amount = null;
      this.mandatory = false;
      this.paymentFrequency = null;
      this.paymentTime = null;
      this.paymentMethods = [];
      this.chargable = false;
      this.commissionPercentage = null;
      this.invoiceHolder = null;
      this.vatPercentage = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    saveContractService() {
      // TODO: Activar o desactivar el boto de guardar segons validació del formulari.
      if (this.contractService) {
        this.updateContractService();
      } else {
        this.createContractService();
      }
    },
    createContractService() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/addService", {
          contract: this.contract["@id"],
          service: this.service,
          price: this.pvp ? Math.round(this.pvp * 100) : null,
          costPrice: this.costPrice ? Math.round(this.costPrice * 100) : null,
          amount: parseInt(this.amount, 10),
          mandatory: this.mandatory || false,
          paymentFrequency: this.paymentFrequency,
          paymentTime: this.paymentTime,
          paymentMethods: this.paymentMethods,
          chargable: this.chargable || false,
          invoiceHolder: this.invoiceHolder,
          commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
            ? null
            : parseFloat(this.commissionPercentage),
          vatPercentage: isNaN(parseFloat(this.vatPercentage))
            ? null
            : parseFloat(this.vatPercentage),
        })
        .then(() => this.$emit("service-added"))
        .catch(() => {
          this.$emit("add-service-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    updateContractService() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateService", {
          uuid: this.contractService.uuid,
          service: this.service,
          price: this.pvp ? Math.round(this.pvp * 100) : null,
          costPrice: this.costPrice ? Math.round(this.costPrice * 100) : null,
          amount: parseInt(this.amount, 10),
          mandatory: this.mandatory || false,
          paymentFrequency: this.paymentFrequency,
          paymentTime: this.paymentTime,
          paymentMethods: this.paymentMethods,
          chargable: this.chargable || false,
          invoiceHolder: this.invoiceHolder,
          commissionPercentage: isNaN(parseFloat(this.commissionPercentage))
            ? null
            : parseFloat(this.commissionPercentage),
          vatPercentage: isNaN(parseFloat(this.vatPercentage))
            ? null
            : parseFloat(this.vatPercentage),
        })
        .then(() => this.$emit("service-edited"))
        .catch(() => {
          this.$emit("edit-service-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
