<template>
  <div>
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      sort-icon-left
      :items="services"
      show-empty
      :empty-text="$t('errors.emptyServicesTable.title')"
      :empty-filtered-text="$t('errors.emptyServicesTable.title')"
      :fields="fields"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2" />
          <strong>{{ $t("Carregant") }}...</strong>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-dropdown variant="link" no-caret>
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item @click="onEditServiceClicked(data.item.uuid)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edita</span>
          </b-dropdown-item>

          <b-dropdown-item @click="confirmDeleteService(data.item.uuid)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Elimina</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable, BDropdown, BDropdownItem } from "bootstrap-vue";

export default {
  components: {
    BTable,
    BDropdown,
    BDropdownItem,
  },
  props: {
    services: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isBusy: false,
      sortBy: "name",
      sortDesc: false,

      totalRows: 1,

      fields: [
        { key: "name", label: this.$t("Nom"), sortable: true },
        { key: "mandatory", label: this.$t("Obligatori"), sortable: true },
        { key: "costPrice", label: this.$t("Cost"), sortable: true },
        { key: "price", label: this.$t("PVP"), sortable: true },
        {
          key: "paymentFrequency",
          label: this.$t("Freqüència pagament"),
          sortable: true,
        },
        {
          key: "paymentTime",
          label: this.$t("Moment pagament"),
          sortable: true,
        },
        {
          key: "paymentMethods",
          label: this.$t("Mètode pagament"),
          sortable: true,
        },
        {
          key: "amount",
          label: this.$t("Serveis disponibles"),
          sortable: true,
        },
        {
          key: "actions",
          label: this.$t("Accions"),
          thStyle: { width: "95px" },
        },
      ],
    };
  },
  methods: {
    onEditServiceClicked(serviceUuid) {
      this.$emit("edit-service-click", serviceUuid);
    },
    confirmDeleteService(serviceUuid) {
      this.$swal({
        title: "Eliminar servei",
        text: "Estàs a punt d'eliminar aquest servei, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteService(serviceUuid);
      });
    },
    deleteService(serviceUuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("contract/deleteService", serviceUuid)
        .then(() => this.$emit("service-deleted"))
        .catch(() => {
          this.$emit("service-deleted-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
