<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Detalls">
      <b-row>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Estat</h5>
          <v-select
            v-if="editMode"
            v-model="status"
            class="status-select"
            :options="contractStatusOptions"
            :reduce="(option) => option.value"
          />
          <b-badge v-else :variant="contractStatusColor">
            {{ contractStatus }}
          </b-badge>
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Allotjament</h5>
          <b-accommodation
            :uuid="accommodationUuid"
            :name="accommodationName"
          />
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Propietari</h5>
          <div>
            <div>{{ ownerName }}</div>
            <small v-if="ownerRepresentative">
              {{ `Representat per ${ownerRepresentative}` }}
            </small>
          </div>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Data d'inici</h5>
          <b-datepicker v-if="editMode" v-model="startDate" />
          <span v-else>{{ contractStartDate }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Data de finalització</h5>
          <b-datepicker v-if="editMode" v-model="endDate" />
          <span v-else>{{ contractEndDate }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Data de signatura</h5>
          <b-datepicker v-if="editMode" v-model="signatureDate" />
          <span v-else>{{ contractSignatureDate }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Renovació automàtica</h5>
          <b-form-checkbox v-if="editMode" v-model="autoRenewal" switch />
          <span v-else>{{ contractAutoRenewal }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Tipus de gestió</h5>
          <v-select
            v-if="editMode"
            v-model="managementType"
            placeholder="Selecciona un tipus de gestió"
            :options="managementTypeOptions"
            :reduce="(option) => option.value"
            :clearable="false"
          />
          <span v-else>{{ contractManagementType }}</span>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>

      <!-- MODALS -->
      <b-modal />
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormCheckbox,
  BBadge,
  BModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import BDatepicker from "@foravila-core/components/b-datepicker/BDatepicker.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import {
  contractStatusOptions,
  managementTypeOptions,
} from "@/utils/select-options";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import {
  getContractStatusColor,
  getContractStatusName,
  getIdTypeName,
  getManagementTypeName,
  notifyError,
} from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton,
    BDatepicker,
    BFormCheckbox,
    BBadge,
    BModal,
    BAccommodation,
  },
  data() {
    return {
      editMode: false,
      status: null,
      startDate: null,
      endDate: null,
      signatureDate: null,
      autoRenewal: false,
      managementType: null,
      contractStatusOptions,
      managementTypeOptions,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractStatus() {
      return (
        getContractStatusName(this.contract?.status) || this.$t("No disponible")
      );
    },
    contractStatusColor() {
      return getContractStatusColor(this.contract?.status) || "secondary";
    },
    accommodationName() {
      return this.contract?.accommodation?.name || this.$t("No disponible");
    },
    accommodationUuid() {
      return this.contract?.accommodation?.uuid || null;
    },
    ownerName() {
      return this.contract?.owner?.fullName || this.$t("No disponible");
    },
    contractStartDate() {
      return (
        formatDateStringToDate(this.contract?.startDate) ||
        this.$t("No disponible")
      );
    },
    contractEndDate() {
      return (
        formatDateStringToDate(this.contract?.endDate) ||
        this.$t("No disponible")
      );
    },
    contractSignatureDate() {
      return (
        formatDateStringToDate(this.contract?.signedAt) ||
        this.$t("No disponible")
      );
    },
    contractAutoRenewal() {
      return this.contract?.autoRenewal ? this.$t("Sí") : this.$t("No");
    },
    contractManagementType() {
      return (
        getManagementTypeName(this.contract?.managementType) ||
        this.$t("No disponible")
      );
    },
    ownerRepresentative() {
      const text = [];
      if (this.contract?.representativeFullName)
        text.push(this.contract.representativeFullName);
      if (
        this.contract?.representativeIdType &&
        this.contract?.representativeIdNumber
      ) {
        text.push(
          `(${getIdTypeName(this.contract.representativeIdType)}: ${
            this.contract.representativeIdNumber
          })`
        );
      }
      return text.length > 0 ? text.join(" ") : null;
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.status = this.contract?.status || null;
      this.startDate = this.contract?.startDate || null;
      this.endDate = this.contract?.endDate || null;
      this.signatureDate = this.contract?.signedAt || null;
      this.autoRenewal = this.contract?.autoRenewal || null;
      this.managementType = this.contract?.managementType || null;
    },
    resetInputs() {
      this.status = null;
      this.startDate = null;
      this.signatureDate = null;
      this.autoRenewal = false;
      this.managementType = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      const signedAt =
        this.status === "ACTIVE" && !this.signatureDate
          ? formatDateObjectToDatabaseDate(new Date())
          : this.signatureDate;

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          status: this.status,
          startDate: this.startDate,
          endDate: this.endDate,
          signedAt,
          autoRenewal: this.autoRenewal,
          managementType: this.managementType,
        })
        .then(() => {
          this.editMode = false;
          this.$emit("updated");
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
