<template>
  <div id="contract-provider-form" class="py-1">
    <b-row class="mb-2">
      <!-- PROVIDER -->
      <b-col cols="12" class="mb-2">
        <fr-form-select
          label="Proveïdor"
          :options="providerOptions"
          v-model="provider"
        />
      </b-col>

      <!-- PROVIDER -->
      <b-col cols="12">
        <fr-form-select
          label="Servei"
          :options="providerTypeOptions"
          v-model="type"
        />
      </b-col>
    </b-row>

    <!-- BUTTONS -->
    <b-row class="d-flex justify-content-between justify-content-sm-end">
      <b-col cols="6" sm="auto">
        <b-button block variant="light" @click="cancel"> Cancel·la </b-button>
      </b-col>
      <b-col cols="6" sm="auto">
        <b-button
          block
          variant="primary"
          @click="saveContractProvider"
          :disabled="saveButtonDisabled"
        >
          Guarda
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import { FrFormSelect } from "@/@foravila-core/components/fr-form";
import { providerTypeOptions } from "@/utils/select-options";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    FrFormSelect,
  },
  props: {
    contractProvider: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      provider: null,
      type: null,
      providerTypeOptions,
    };
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
    providers() {
      return this.$store.getters["providers/providers"];
    },
    providerOptions() {
      if (!this.providers?.length) {
        return [];
      }

      const options = [...this.providers];

      options.sort((a, b) => {
        return (a.fullName || "").localeCompare(b.fullName || "");
      });

      return options.map((provider) => ({
        label: `${provider.fullName} - ${provider.idNumber}`,
        value: provider["@id"],
      }));
    },
    saveButtonDisabled() {
      return !this.provider || !this.type;
    },
  },
  created() {
    if (this.contractProvider) {
      this.initForm();
    }
  },
  methods: {
    initForm() {
      if (!this.contractProvider) {
        return;
      }

      this.provider = this.contractProvider.provider["@id"];
      this.type = this.contractProvider.type;
    },
    resetForm() {
      this.provider = null;
      this.type = null;
    },
    cancel() {
      this.resetForm();
      this.$emit("cancel");
    },
    saveContractProvider() {
      if (this.contractProvider) {
        this.updateContractProvider();
      } else {
        this.createContractProvider();
      }
    },
    createContractProvider() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/addProvider", {
          contract: this.contract["@id"],
          provider: this.provider,
          type: this.type,
        })
        .then(() => this.$emit("provider-added"))
        .catch(() => {
          this.$emit("add-provider-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    updateContractProvider() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateProvider", {
          uuid: this.contractProvider.uuid,
          provider: this.provider,
          type: this.type,
        })
        .then(() => this.$emit("provider-edited"))
        .catch(() => {
          this.$emit("edit-provider-error");
          // TODO: Log error in Sentry? Only if is not logged in the store
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss" scoped>
#contract-provider-form {
  min-height: 400px;
  // To allow the vue-select to display the options without scrolling
}
</style>
