<template>
  <div
    class="d-flex justify-content-between align-items-center py-1"
    :class="{ 'border-bottom': borderBottom, 'border-top': borderTop }"
  >
    <div>
      <h5 :class="{ 'mb-0': !subtitle }">
        {{ title }}
      </h5>
      <p v-if="subtitle" class="mb-0">
        {{ subtitle }}
      </p>
    </div>
    <div v-if="readOnly">
      {{ itemValueText }}
    </div>
    <b-form-radio-group
      v-else
      :checked="value"
      :options="options"
      @change="onChange"
    />
  </div>
</template>
<script>
import { BFormRadioGroup } from "bootstrap-vue";
export default {
  components: {
    BFormRadioGroup,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    borderBottom: {
      type: Boolean,
      default: false,
    },
    borderTop: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [
        { text: this.$t("No"), value: false },
        { text: this.$t("Sí"), value: true },
      ],
    };
  },
  computed: {
    itemValueText() {
      return this.value ? this.$t("Sí") : this.$t("No");
    },
  },
  methods: {
    onChange(selectedValue) {
      this.$emit("input", selectedValue);
    },
  },
};
</script>
