<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Danger zone">
      <b-row>
        <b-col cols="12" class="mb-1">
          <b-button
            size="xl"
            variant="danger"
            class="shake"
            @click="confirmDeleteContract"
          >
            Elimina el contracte
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import { BCard, BRow, BCol, BOverlay, BButton } from "bootstrap-vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
  },
  methods: {
    confirmDeleteContract() {
      this.$swal({
        title: "Eliminar contracte",
        text: "Estàs a punt d'eliminar aquest contracte, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteContract();
      });
    },
    deleteContract() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("contract/deleteContract", this.contract.uuid)
        .then(() => {
          notifySuccess(
            "Contracte eliminat",
            "El contracte ha estat eliminat correctament."
          );
          this.$router.push({ name: "foravila-contracts-list" });
        })
        .catch(() => {
          notifyError(
            "Contracte no eliminat",
            "Hi ha hagut un error al eliminar el contracte."
          );
          // TODO: log error in Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style scoped lang="css">
.shake:hover {
  animation: shake 0.7s infinite;
}

@keyframes shake {
  0%,
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-1px, -1px) rotate(-1deg);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(1px, 1px) rotate(1deg);
  }
}
</style>
