<template>
  <b-overlay :show="loading" rounded="sm" variant="white">
    <b-card title="Llistat de reserves">
      <contract-bookings-table
        :bookings="mappedBookings"
        :loading="loadingBookings"
        show-search
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";
import ContractBookingsTable from "@/views/contracts/contract/components/ContractBookingsTable.vue";

export default {
  components: {
    BOverlay,
    BCard,
    ContractBookingsTable,
  },
  computed: {
    loading() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    loadingBookings() {
      return this.$store.getters["contract/loadingBookings"];
    },
    bookings() {
      return this.$store.getters["contract/bookings"];
    },
    mappedBookings() {
      if (this.bookings.length > 0) {
        return this.bookings.map((booking) => ({
          uuid: booking.uuid || this.$t("No definit"),
          date: booking.date || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          status: booking.status || this.$t("No definit"),
          source: booking.source || this.$t("No definit"),
          client: booking.client?.fullName || this.$t("No definit"),
          clientUuid: booking.client?.uuid || null,
          checkin: booking.checkin || this.$t("No definit"),
          checkout: booking.checkout || this.$t("No definit"),
          highlighted: booking.highlighted || false,
        }));
      }
      return [];
    },
  },
};
</script>
