<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Informació dels hostes">
      <b-row>
        <b-col cols="12">
          <h5>Enviar les dades de contacte del client al propietari?</h5>
          <b-form-checkbox
            v-if="editMode"
            v-model="clientDetailsProvided"
            switch
          />
          <span v-else>{{ contractClientDetailsProvided }}</span>
        </b-col>
        <b-col cols="12" class="mt-2">
          <h5>Enviar les dades dels hostes a la policia?</h5>
          <b-form-checkbox
            v-if="editMode"
            v-model="guestsRegistrationProvided"
            switch
          />
          <span v-else>{{ contractGuestsRegistrationProvided }}</span>
        </b-col>
        <b-col cols="12" class="mt-2">
          <h5>
            Incloure el registre a la policia en el grup de Foravila Rentals?
          </h5>
          <b-form-checkbox
            v-if="editMode"
            v-model="guestsRegistrationInGroup"
            switch
            :disabled="!guestsRegistrationProvided"
          />
          <span v-else>{{ contractGuestsRegistrationInGroup }}</span>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
  },
  data() {
    return {
      editMode: false,
      clientDetailsProvided: false,
      guestsRegistrationProvided: false,
      guestsRegistrationInGroup: false,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractClientDetailsProvided() {
      return this.contract?.clientDetailsProvided ? "Sí" : "No";
    },
    contractGuestsRegistrationProvided() {
      return this.contract?.guestsRegistrationProvided ? "Sí" : "No";
    },
    contractGuestsRegistrationInGroup() {
      return this.contract?.guestsRegistrationInGroup ? "Sí" : "No";
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
    guestsRegistrationProvided(value) {
      this.guestsRegistrationInGroup = value;
    },
  },
  methods: {
    initInputs() {
      this.clientDetailsProvided =
        this.contract?.clientDetailsProvided || false;
      this.guestsRegistrationProvided =
        this.contract?.guestsRegistrationProvided || false;
      this.guestsRegistrationInGroup =
        this.contract?.guestsRegistrationInGroup || false;
    },
    resetInputs() {
      this.selfCheckin = false;
      this.clientDetailsProvided = false;
      this.guestsRegistrationProvided = false;
      this.guestsRegistrationInGroup = false;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          clientDetailsProvided: this.clientDetailsProvided,
          guestsRegistrationProvided: this.guestsRegistrationProvided,
          guestsRegistrationInGroup: this.guestsRegistrationProvided
            ? this.guestsRegistrationInGroup
            : false,
        })
        .then(() => {
          this.editMode = false;
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
