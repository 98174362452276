<template>
  <contract-discount-form
    description="Configura un descompte per a reserves fetes a darrera hora."
    :percentage="contractLastMinuteDiscount"
    percentage-label="Introudeix el percentatge"
    percentage-description="Aquest percentatge s'aplicarà a l'import propietari"
    :days="contractLastMinuteDiscountDuration"
    days-label="Introudeix la duració"
    days-description="Defineix els dies d'antel·lació màxima al començament de l'estada"
    @cancel="cancel"
    @save="save"
  />
</template>

<script>
import ContractDiscountForm from "@/views/contracts/contract/components/ContractDiscountForm.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    ContractDiscountForm,
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractLastMinuteDiscount() {
      return this.contract?.lastMinuteDiscount || null;
    },
    contractLastMinuteDiscountDuration() {
      return this.contract?.lastMinuteDiscountDuration || null;
    },
  },
  methods: {
    save(form) {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          lastMinuteDiscount: form.percentage,
          lastMinuteDiscountDuration: form.days,
        })
        .then(() => {
          // TODO: log the contract update?
          this.$emit("updated");
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
