<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Condicions de Publicació">
      <b-row>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Tipus de reserves acceptades</h5>
          <b-form-checkbox-group
            v-if="editMode"
            v-model="acceptedBookingTypes"
            :options="acceptedBookingTypeOptions"
            value-field="value"
            text-field="label"
          />
          <span v-else>{{ contractAcceptedBookingTypes }}</span>
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Prioritat de publicació</h5>
          <b-form-checkbox v-if="editMode" v-model="postingPriority" switch />
          <span v-else>
            {{ postingPriority ? $t("Sí") : $t("No") }}
          </span>
        </b-col>
        <b-col cols="4" class="mt-1 mt-md-0">
          <h5>Canals amb prioritat</h5>
          <v-select
            v-if="editMode"
            v-model="postingPriorityOtas"
            placeholder="Selecciona un tipus de reserva"
            :options="otaOptions"
            :reduce="(option) => option.value"
            multiple
            :close-on-select="false"
          />
          <span v-else>{{ contractPostingPriorityOtas }}</span>
        </b-col>
        <b-col cols="4" class="mt-1">
          <h5>Garantía d'igualtat de preus</h5>
          <b-form-checkbox
            v-if="editMode"
            v-model="equalityGuaranteeAccepted"
            switch
          />
          <span v-else>
            {{ equalityGuaranteeAccepted ? $t("Sí") : $t("No") }}
          </span>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormCheckbox,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { otaOptions, acceptedBookingTypeOptions } from "@/utils/select-options";
import {
  getAcceptedBookingTypeName,
  getOtaName,
  notifyError,
} from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    vSelect,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
  },
  data() {
    return {
      editMode: false,
      acceptedBookingTypes: [],
      postingPriority: false,
      postingPriorityOtas: [],
      equalityGuaranteeAccepted: false,
      otaOptions,
      acceptedBookingTypeOptions,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractAcceptedBookingTypes() {
      if (!this.contract?.acceptedBookingTypes?.length) {
        return this.$t("No disponible");
      }

      return this.contract.acceptedBookingTypes
        .map((typeCode) => getAcceptedBookingTypeName(typeCode))
        .join(",  ");
    },
    contractPostingPriorityOtas() {
      if (!this.contract?.postingPriorityOtas?.length) {
        return this.$t("No disponible");
      }

      return this.contract.postingPriorityOtas
        .map((otaCode) => getOtaName(otaCode))
        .join(", ");
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.acceptedBookingTypes = this.contract?.acceptedBookingTypes || [];
      this.postingPriority = this.contract?.postingPriority || false;
      this.postingPriorityOtas = this.contract?.postingPriorityOtas || [];
      this.equalityGuaranteeAccepted =
        this.contract?.equalityGuaranteeAccepted || false;
    },
    resetInputs() {
      this.acceptedBookingTypes = [];
      this.postingPriority = false;
      this.postingPriorityOtas = [];
      this.equalityGuaranteeAccepted = false;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          acceptedBookingTypes: this.acceptedBookingTypes,
          postingPriority: this.postingPriority,
          postingPriorityOtas: this.postingPriorityOtas,
          equalityGuaranteeAccepted: this.equalityGuaranteeAccepted,
        })
        .then(() => {
          this.editMode = false;
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
