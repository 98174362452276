<template>
  <b-row>
    <b-col cols="12">
      <v-select
        v-model="selectedCoOwner"
        :options="coOwnersOptions"
        :reduce="(option) => option.value"
      >
        <template #option="{ label, idNumber }">
          <div>{{ label }}</div>
          <small>{{ idNumber }}</small>
        </template>

        <template #selected-option="{ label, idNumber }">
          <div>{{ label }} - {{ idNumber }}</div>
        </template>
      </v-select>
    </b-col>
    <b-col cols="12" class="d-flex justify-content-end">
      <b-button variant="primary" class="mt-1" @click="addCoOwner">
        Afegeix
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    BButton,
  },
  data() {
    return {
      selectedCoOwner: null,
    };
  },
  computed: {
    owners() {
      return this.$store.getters["owners/owners"];
    },
    coOwnersOptions() {
      if (!this.owners.length) return [];
      return this.owners.map((owner) => ({
        label: owner.fullName,
        value: owner["@id"],
        idNumber: owner.idNumber,
      }));
    },
  },
  methods: {
    addCoOwner() {
      this.$emit("add", this.selectedCoOwner);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
