<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card title="Descomptes">
      <!-- LAUNCH DISCOUNT -->
      <contract-item-button
        title="Llançament"
        :subtitle="launchDiscountSubtitle"
        :button-text="$t('Edita')"
        border-bottom
        @click="$bvModal.show('launch-modal')"
      />
      <b-modal
        id="launch-modal"
        title="Descompte de Llançament"
        scrollable
        hide-footer
        centered
      >
        <contract-launch-discount-form
          @cancel="closeLaunchDiscountModal"
          @updated="closeLaunchDiscountModal"
        />
      </b-modal>

      <!-- LONG STAY DISCOUNT -->
      <contract-item-button
        title="Llarga estada"
        :subtitle="longStayDiscountSubtitle"
        :button-text="$t('Edita')"
        border-bottom
        @click="$bvModal.show('long-stay-modal')"
      />
      <b-modal
        id="long-stay-modal"
        title="Descompte de Llarga Estada"
        scrollable
        hide-footer
        centered
      >
        <contract-long-stay-discount-form
          @cancel="closeLongStayDiscountModal"
          @updated="closeLongStayDiscountModal"
        />
      </b-modal>

      <!-- EARLY BOOKING DISCOUNT -->
      <contract-item-button
        title="Early booking"
        :subtitle="earlyBookingDiscountSubtitle"
        :button-text="$t('Edita')"
        border-bottom
        @click="$bvModal.show('early-booking-modal')"
      />
      <b-modal
        id="early-booking-modal"
        title="Descompte de reserva anticipada"
        scrollable
        hide-footer
        centered
      >
        <contract-early-booking-discount-form
          @cancel="closeEarlyBookingDiscountModal"
          @updated="closeEarlyBookingDiscountModal"
        />
      </b-modal>

      <!-- LAST MINUTE DISCOUNT -->
      <contract-item-button
        title="Last minute"
        :subtitle="lastMinuteDiscountSubtitle"
        :button-text="$t('Edita')"
        @click="$bvModal.show('last-minute-modal')"
      />
      <b-modal
        id="last-minute-modal"
        title="Descompte de darrer minut"
        scrollable
        hide-footer
        centered
      >
        <contract-last-minute-discount-form
          @cancel="closeLastMinuteDiscountModal"
          @updated="closeLastMinuteDiscountModal"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard } from "bootstrap-vue";
import ContractItemButton from "@/views/contracts/contract/components/ContractItemButton.vue";
import ContractLaunchDiscountForm from "@/views/contracts/contract/components/ContractLaunchDiscountForm.vue";
import ContractLongStayDiscountForm from "@/views/contracts/contract/components/ContractLongStayDiscountForm.vue";
import ContractEarlyBookingDiscountForm from "@/views/contracts/contract/components/ContractEarlyBookingDiscountForm.vue";
import ContractLastMinuteDiscountForm from "@/views/contracts/contract/components/ContractLastMinuteDiscountForm.vue";

export default {
  components: {
    BOverlay,
    BCard,
    ContractItemButton,
    ContractLaunchDiscountForm,
    ContractLongStayDiscountForm,
    ContractEarlyBookingDiscountForm,
    ContractLastMinuteDiscountForm,
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    launchDiscountSubtitle() {
      const launchDiscount = this.contract?.launchDiscount || null;
      const launchDiscountDuration =
        this.contract?.launchDiscountDuration || null;
      if (!launchDiscount || !launchDiscountDuration) {
        return this.$t("No definit");
      }
      return `Descompte del ${launchDiscount}% durant els ${launchDiscountDuration} dies posteriors a la publicació de l'anunci`;
    },
    longStayDiscountSubtitle() {
      const longStayDiscount = this.contract?.longStayDiscount || null;
      const longStayDiscountDuration =
        this.contract?.longStayDiscountDuration || null;
      if (!longStayDiscount || !longStayDiscountDuration) {
        return this.$t("No definit");
      }
      return `Descompte del ${longStayDiscount}% per a estades superiors a ${longStayDiscountDuration} dies`;
    },
    earlyBookingDiscountSubtitle() {
      const earlyBookingDiscount = this.contract?.earlyBookingDiscount || null;
      const earlyBookingDiscountDuration =
        this.contract?.earlyBookingDiscountDuration || null;
      if (!earlyBookingDiscount || !earlyBookingDiscountDuration) {
        return this.$t("No definit");
      }
      return `Descompte del ${earlyBookingDiscount}% per a reserves realitzades amb una atelació de ${earlyBookingDiscountDuration} dies o més`;
    },
    lastMinuteDiscountSubtitle() {
      const lastMinuteDiscount = this.contract?.lastMinuteDiscount || null;
      const lastMinuteDiscountDuration =
        this.contract?.lastMinuteDiscountDuration || null;
      if (!lastMinuteDiscount || !lastMinuteDiscountDuration) {
        return this.$t("No definit");
      }
      return `Descompte del ${lastMinuteDiscount} % per a reserves realitzades amb una atelació de ${lastMinuteDiscountDuration} dies o menys`;
    },
  },
  methods: {
    closeLaunchDiscountModal() {
      this.$bvModal.hide("launch-modal");
    },
    closeLongStayDiscountModal() {
      this.$bvModal.hide("long-stay-modal");
    },
    closeEarlyBookingDiscountModal() {
      this.$bvModal.hide("early-booking-modal");
    },
    closeLastMinuteDiscountModal() {
      this.$bvModal.hide("last-minute-modal");
    },
  },
};
</script>
