<template>
  <b-overlay :show="loadingContract || deleting" variant="white" :opacity="1">
    <b-card title="Co-propietaris">
      <b-row>
        <b-col cols="12">
          <b-list-group v-if="coOwners.length" flush>
            <b-list-group-item
              v-for="coOwner in coOwners"
              :key="coOwner.uuid"
              class="d-flex justify-content-between align-items-center"
            >
              <span>{{ getCoOwnerInfo(coOwner) }}</span>
              <feather-icon
                icon="Trash2Icon"
                class="ml-25 text-danger cursor-pointer"
                @click="deleteCoOwner(coOwner)"
              />
            </b-list-group-item>
          </b-list-group>
          <span v-else>No hi ha cap co-propietari configurat</span>
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-2">
        <b-col cols="12" sm="auto">
          <b-button
            variant="primary"
            :disabled="!contract"
            @click="openAddCoOwnerModal"
          >
            Afegeix
          </b-button>
        </b-col>
      </b-row>

      <b-modal
        id="add-co-owner-modal"
        title="Afegeix un co-propietari"
        hide-footer
        centered
        no-close-on-backdrop
      >
        <add-co-owner-form @add="addCoOwner" />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";
import AddCoOwnerForm from "@/views/contracts/contract/components/AddCoOwnerForm.vue";
import { notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    BRow,
    BCol,
    BModal,
    AddCoOwnerForm,
    BListGroup,
    BListGroupItem,
  },
  data() {
    return {
      selectedCoOwner: null,
      deleting: false,
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    owner() {
      return this.contract?.owner || null;
    },
    coOwners() {
      return this.contract?.coOwners || [];
    },
  },
  methods: {
    openAddCoOwnerModal() {
      this.$bvModal.show("add-co-owner-modal");
    },
    addCoOwner(coOwner) {
      // TODO: no haver de tornar a fer fetch de tot es contracte quan afegim un nou co-owner
      if (!coOwner) {
        notifyError(
          "Error",
          "El co-propietari no s'ha pogut afegir correctament, si us plau, revisa les dades introduïdes"
        );
        return;
      }

      this.$store.dispatch("app/setLoading", true);

      // TODO: validate fields

      this.$store
        .dispatch("contract/addCoOwner", {
          contract: this.contract["@id"],
          coOwner: coOwner || null,
        })
        .then(() => {
          notifySuccess(
            "Co-propietari afegit",
            "El co-propietari ha estat afegit correctament"
          );
          this.$bvModal.hide("add-co-owner-modal");
          this.$emit("coowner-added");
        })
        .catch(() =>
          notifyError(
            "Error",
            "El co-propietari no s'ha pogut afegir correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    getCoOwnerInfo(coOwner) {
      if (!coOwner) return null;
      const text = [];
      if (coOwner.fullName) text.push(coOwner.fullName);
      if (coOwner.idNumber) text.push(coOwner.idNumber);
      return text.length ? text.join(" - ") : null;
    },
    deleteCoOwner(coOwner) {
      // TODO: no haver de tornar a fer fetch de tot es contracte quan eliminem un co-owner
      if (!coOwner) {
        notifyError(
          "Error",
          "El co-propietari no s'ha pogut eliminar, si us plau, revisa les dades introduïdes"
        );
        return;
      }

      this.deleting = true;

      this.$store
        .dispatch("contract/deleteCoOwner", {
          contract: this.contract["@id"],
          coOwner: coOwner["@id"],
        })
        .then(() => {
          notifySuccess(
            "Co-propietari eliminat",
            "El co-propietari ha estat eliminat correctament"
          );
          this.$bvModal.hide("add-co-owner-modal");
          this.$emit("coowner-deleted");
        })
        .catch(() =>
          notifyError(
            "Error",
            "El co-propietari no s'ha pogut eliminar correctament, si us plau, revisa les dades introduïdes"
          )
        )
        .finally(() => {
          this.deleting = false;
        });
    },
  },
};
</script>
