<template>
  <b-table
    striped
    responsive="sm"
    :items="rates"
    :fields="fields"
    show-empty
    :empty-text="$t('errors.emptyTable.title')"
    :empty-filtered-text="$t('errors.emptyTable.title')"
    :busy="loading"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-2" />
        <strong>{{ $t("Carregant") }}...</strong>
      </div>
    </template>

    <!-- Column: Dates -->
    <template #cell(startDate)="data">
      <div class="d-flex align-items-center">
        {{ formatDateStringToDate(data.item.startDate) }}
        <feather-icon icon="ArrowRightIcon" class="mx-50" />
        {{ formatDateStringToDate(data.item.endDate) }}
      </div>
    </template>

    <!-- Column: price -->
    <template #cell(price)="data">
      {{ formatCurrency(data.item.price) }}
    </template>

    <!-- Column: Policy Name -->
    <template #cell(bookingPolicy)="data">
      {{ getBookingPolicyName(data.item.bookingPolicy) }}
    </template>
  </b-table>
</template>

<script>
import { BTable, BSpinner } from "bootstrap-vue";
import { formatDateStringToDate, formatCurrency } from "@/utils/formatters";
import { getDaysText, getWeekdaysText } from "@/utils/methods";

export default {
  components: {
    BTable,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    rates: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formatDateStringToDate,
      formatCurrency,
      getWeekdaysText,
      getDaysText,
    };
  },
  computed: {
    bookingPolicies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    fields() {
      return [
        {
          key: "startDate",
          label: this.$t("Dates"),
          sortable: true,
          thStyle: { width: "40px" },
        },
        {
          key: "price",
          label: "Preu per nit",
          sortable: true,
          thStyle: { width: "150px" },
        },
        {
          key: "bookingPolicy",
          label: "Política de reserva",
          sortable: true,
          thStyle: { width: "300px" },
        },
      ];
    },
  },
  methods: {
    getBookingPolicyName(bookingPolicyId) {
      if (!bookingPolicyId) {
        return this.$t("No definit");
      }
      const foundPolicy = this.bookingPolicies.find(
        (policy) => policy["@id"] === bookingPolicyId
      );
      return foundPolicy?.name || bookingPolicyId;
    },
    onDeleteRate(uuid) {
      this.$swal({
        title: "Eliminar",
        text: "Estàs a punt d'eliminar aquest element, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteRate(uuid);
      });
    },
    deleteRate(uuid) {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("contract/deleteOwnerRate", uuid)
        .then(() => {
          this.$emit("rate-deleted", uuid);
        })
        .catch(() => {
          this.$emit("delete-rate-error", uuid);
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>
