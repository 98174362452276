<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card title="Proveïdors">
      <!-- TABLE -->
      <contract-providers-table
        :providers="mappedContractProviders"
        @edit-provider-click="onEditProviderClicked"
        @provider-deleted="onProviderDeleted"
        @provider-deleted-error="onDeleteProviderError"
      />

      <!-- NEW SERVICE BUTTON  -->
      <b-row class="mt-2 d-flex justify-content-end">
        <b-col cols="3" md="auto" class="mb-1 mb-md-0">
          <b-button
            variant="primary"
            block
            @click.prevent="onAddProviderClicked"
          >
            <feather-icon icon="PlusIcon" class="d-block d-md-none" />
            <span class="d-none d-md-inline">
              {{ $t("Afegeix proveïdor") }}
            </span>
          </b-button>
        </b-col>
      </b-row>

      <!-- ADD PROVIDER MODAL -->
      <b-modal
        id="add-contract-provider-modal"
        title="Afegeix un proveïdor"
        scrollable
        hide-footer
      >
        <contract-provider-form
          @cancel="onAddProviderCancel"
          @provider-added="onProviderAdded"
          @add-provider-error="onAddProviderError"
        />
      </b-modal>

      <!-- EDIT PROVIDER MODAL -->
      <b-modal
        id="edit-contract-provider-modal"
        title="Edita el proveïdor"
        scrollable
        hide-footer
      >
        <contract-provider-form
          :contract-provider="selectedProvider"
          @cancel="onEditProviderCancel"
          @provider-edited="onProviderEdited"
          @edit-provider-error="onEditProviderError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import ContractProvidersTable from "@/views/contracts/contract/components/ContractProvidersTable.vue";
import ContractProviderForm from "@/views/contracts/contract/components/ContractProviderForm.vue";
import {
  getContractProviderTypeName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    ContractProvidersTable,
    ContractProviderForm,
  },
  data() {
    return {
      editMode: false,
      selectedProvider: null,
    };
  },
  computed: {
    loading() {
      return this.loadingProviders || this.loadingContract;
    },
    loadingProviders() {
      const providers = this.$store.getters["providers/loading"];
      const contractProviders =
        this.$store.getters["contract/loadingProviders"];
      return providers || contractProviders;
    },
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractProviders() {
      return this.$store.getters["contract/providers"];
    },
    mappedContractProviders() {
      if (!this.contractProviders?.length) {
        return [];
      }

      return this.contractProviders.map((contractProvider) => ({
        uuid: contractProvider.uuid || this.$t("No definit"),
        name: contractProvider.provider?.fullName || this.$t("No definit"),
        idNumber: contractProvider.provider?.idNumber || this.$t("No definit"),
        type: getContractProviderTypeName(
          contractProvider.type,
          this.$i18n.locale
        ),
      }));
    },
  },
  methods: {
    onAddProviderClicked() {
      this.$bvModal.show("add-contract-provider-modal");
    },
    onAddProviderCancel() {
      this.$bvModal.hide("add-contract-provider-modal");
    },
    onProviderAdded() {
      this.$bvModal.hide("add-contract-provider-modal");
      notifySuccess(
        "Proveïdor afegit",
        "El proveïdor ha estat afegit correctament"
      );
    },
    onAddProviderError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir el proveïdor, intenta-ho de nou"
      );
    },
    onEditProviderClicked(contractProviderUuid) {
      this.selectedProvider = this.contractProviders.find(
        (contractProvider) => contractProvider.uuid === contractProviderUuid
      );
      this.$bvModal.show("edit-contract-provider-modal");
    },
    onEditProviderCancel() {
      this.$bvModal.hide("edit-contract-provider-modal");
    },
    onProviderEdited() {
      this.$bvModal.hide("edit-contract-provider-modal");
      notifySuccess(
        "Proveïdor editat",
        "El proveïdor ha estat editat correctament"
      );
    },
    onEditProviderError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar editar el proveïdor"
      );
    },
    onProviderDeleted() {
      this.$bvModal.hide("edit-contract-provider-modal");
      notifySuccess(
        "Proveïdor eliminat",
        "El proveïdor ha estat eliminat correctament"
      );
    },
    onDeleteProviderError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el proveïdor"
      );
    },
  },
};
</script>
