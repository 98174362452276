<template>
  <contract-discount-form
    description="Configura un descompte per als dies posteriors a la publicació de l'allotjament."
    :percentage="contractLaunchDiscount"
    percentage-label="Introudeix el percentatge"
    percentage-description="Aquest percentatge s'aplicarà a l'import propietari"
    :days="contractLaunchDiscountDuration"
    days-label="Introudeix la duració"
    days-description="Defineix la duració del descompte després de la publicació de l'anunci"
    @cancel="cancel"
    @save="save"
  />
</template>

<script>
import ContractDiscountForm from "@/views/contracts/contract/components/ContractDiscountForm.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    ContractDiscountForm,
  },
  computed: {
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractLaunchDiscount() {
      return this.contract?.launchDiscount || null;
    },
    contractLaunchDiscountDuration() {
      return this.contract?.launchDiscountDuration || null;
    },
  },
  methods: {
    save(form) {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          launchDiscount: form.percentage,
          launchDiscountDuration: form.days,
        })
        .then(() => {
          // TODO: log the contract update?
          this.$emit("updated");
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
