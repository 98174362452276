<template>
  <b-overlay :show="loading" variant="white" :opacity="1">
    <b-card title="Serveis">
      <!-- TABLE -->
      <contract-services-table
        :services="mappedContractServices"
        @edit-service-click="onEditServiceClicked"
        @service-deleted="onServiceDeleted"
        @service-deleted-error="onDeleteServiceError"
      />

      <!-- NEW SERVICE BUTTON  -->
      <b-row class="mt-2 d-flex justify-content-end">
        <b-col cols="3" md="auto" class="mb-1 mb-md-0">
          <b-button
            variant="primary"
            block
            @click.prevent="onAddServiceClicked"
          >
            <feather-icon icon="PlusIcon" class="d-block d-md-none" />
            <span class="d-none d-md-inline">{{ $t("Afegeix servei") }}</span>
          </b-button>
        </b-col>
      </b-row>

      <!-- ADD SERVICE MODAL -->
      <b-modal
        :id="'add-contract-service-modal'"
        title="Afegeix un servei"
        scrollable
        hide-footer
      >
        <contract-service-form
          @cancel="onAddServiceCancel"
          @service-added="onServiceAdded"
          @add-service-error="onAddServiceError"
        />
      </b-modal>

      <!-- EDIT SERVICE MODAL -->
      <b-modal
        :id="'edit-contract-service-modal'"
        title="Edita el servei"
        scrollable
        hide-footer
      >
        <contract-service-form
          :contract-service="selectedService"
          @cancel="onEditServiceCancel"
          @service-edited="onServiceEdited"
          @edit-service-error="onEditServiceError"
        />
      </b-modal>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BButton, BCol, BRow } from "bootstrap-vue";
import ContractServicesTable from "@/views/contracts/contract/components/ContractServicesTable.vue";
import ContractServiceForm from "@/views/contracts/contract/components/ContractServiceForm.vue";
import {
  notifyError,
  notifySuccess,
  getPaymentFrequencyName,
  getPaymentTimeName,
  getPaymentMethodName,
  getServiceName,
} from "@/utils/methods";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    BButton,
    ContractServicesTable,
    ContractServiceForm,
    BCol,
    BRow,
  },
  data() {
    return {
      selectedService: null,
    };
  },
  computed: {
    loading() {
      return this.loadingServices || this.loadingContract;
    },
    loadingServices() {
      const services = this.$store.getters["services/loading"];
      const contractServices = this.$store.getters["contract/loadingServices"];
      return services || contractServices;
    },
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractServices() {
      return this.$store.getters["contract/services"];
    },
    mappedContractServices() {
      if (!this.contractServices.length) return [];

      return this.contractServices.map((contractService) => ({
        uuid: contractService.uuid || this.$t("No definit"),
        name:
          getServiceName(contractService.service, this.$i18n.locale) ||
          this.$t("No definit"),
        mandatory: contractService.mandatory ? "Sí" : "No",
        price: formatCurrency(contractService.price) || this.$t("No definit"),
        costPrice:
          formatCurrency(contractService.costPrice) || this.$t("No definit"),
        paymentFrequency:
          getPaymentFrequencyName(contractService.paymentFrequency) ||
          this.$t("No definit"),
        paymentTime:
          getPaymentTimeName(contractService.paymentTime) ||
          this.$t("No definit"),
        paymentMethods:
          this.getPaymentMethodsText(contractService.paymentMethods) ||
          this.$t("No definit"),
        amount: contractService.amount || this.$t("No definit"),
      }));
    },
  },
  methods: {
    getPaymentMethodsText(paymentMethods) {
      if (!paymentMethods || paymentMethods.length === 0) {
        return null;
      }
      const text = [];
      paymentMethods.forEach((paymentMethod) => {
        if (paymentMethod) {
          text.push(getPaymentMethodName(paymentMethod));
        }
      });
      return text.join(", ");
    },
    onAddServiceClicked() {
      this.$bvModal.show("add-contract-service-modal");
    },
    onAddServiceCancel() {
      this.$bvModal.hide("add-contract-service-modal");
    },
    onServiceAdded() {
      this.$bvModal.hide("add-contract-service-modal");
      notifySuccess("Servei afegit", "El servei ha estat afegit correctament");
    },
    onAddServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar afegir el servei");
    },
    onEditServiceClicked(serviceUuid) {
      this.selectedService = this.contractServices.find(
        (contractService) => contractService.uuid === serviceUuid
      );
      this.$bvModal.show("edit-contract-service-modal");
    },
    onEditServiceCancel() {
      this.$bvModal.hide("edit-contract-service-modal");
    },
    onServiceEdited() {
      this.$bvModal.hide("edit-contract-service-modal");
      notifySuccess("Servei editat", "El servei ha estat editat correctament");
    },
    onServiceDeleted() {
      this.$bvModal.hide("edit-contract-service-modal");
      notifySuccess(
        "Servei eliminat",
        "El servei ha estat eliminat correctament"
      );
    },
    onDeleteServiceError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar el servei"
      );
    },
    onEditServiceError() {
      notifyError("Error", "Hi ha hagut un error al intentar editar el servei");
    },
  },
};
</script>

<style lang="scss"></style>
