<template>
  <b-card id="rates-card" title="Tarifes propietari">
    <!-- TABLE -->
    <contract-rates-table
      :loading="loadingOwnerRates"
      :rates="groupedOwnerRates"
      @delete-rate-error="onDeleteRateError"
    />
    <!-- BUTTONS -->
    <b-row class="mt-2 d-flex justify-content-end">
      <b-col cols="12" md="auto">
        <b-button
          @click="$bvModal.show('add-rates-modal')"
          variant="primary"
          block
        >
          Afegeix tarifa
        </b-button>
      </b-col>
    </b-row>

    <!-- ADD RATE MODAL -->
    <b-modal
      :id="'add-rates-modal'"
      title="Afegeix una tarifa"
      scrollable
      hide-footer
    >
      <contract-rates-form
        @owner-rates-added="onOwnerRatesAdded"
        @add-owner-rates-error="onAddRateError"
      />
    </b-modal>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton } from "bootstrap-vue";
import ContractRatesTable from "@/views/contracts/contract/components/ContractRatesTable.vue";
import ContractRatesForm from "@/views/contracts/contract/components/ContractRatesForm.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    ContractRatesTable,
    ContractRatesForm,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    loading() {
      return this.loadingContract || this.loadingOwnerRates;
    },
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/loading"];
    },
    loadingOwnerRates() {
      return this.$store.getters["contract/loadingOwnerRates"];
    },
    ownerRates() {
      return this.$store.getters["contract/ownerRates"];
    },
    mappedOwnerRates() {
      if (!this.ownerRates?.length) {
        return [];
      }

      return this.ownerRates.map((ownerRate) => ({
        date: ownerRate.date,
        price: ownerRate.price,
        bookingPolicy: ownerRate.bookingPolicy
          ? ownerRate.bookingPolicy["@id"]
          : null,
      }));
    },
    groupedOwnerRates() {
      if (!this.ownerRates.length) {
        return [];
      }

      const dateRanges = [];
      const currentRange = {
        startDate: null,
        endDate: null,
        price: null,
        bookingPolicy: null,
      };

      for (let i = 0; i < this.ownerRates.length; i++) {
        if (i === 0) {
          currentRange.startDate = this.ownerRates[i].date;
          currentRange.endDate = this.ownerRates[i].date;
          currentRange.price = this.ownerRates[i].price;
          currentRange.bookingPolicy = this.ownerRates[i].bookingPolicy
            ? this.ownerRates[i].bookingPolicy["@id"]
            : null;
        } else {
          if (this.ownerRates[i].price === this.ownerRates[i - 1].price) {
            currentRange.endDate = this.ownerRates[i].date;
          } else {
            dateRanges.push(this.getCurrentRange(currentRange));

            currentRange.startDate = this.ownerRates[i].date;
            currentRange.endDate = this.ownerRates[i].date;
            currentRange.price = this.ownerRates[i].price;
            currentRange.bookingPolicy = this.ownerRates[i].bookingPolicy
              ? this.ownerRates[i].bookingPolicy["@id"]
              : null;
          }
        }
      }

      // Push the last range
      dateRanges.push(this.getCurrentRange(currentRange));

      return dateRanges;
    },
    bookingPolicies() {
      return this.$store.getters["bookingPolicies/policies"];
    },
    defaultBookingPolicy() {
      if (!this.bookingPolicies?.length) {
        return null;
      }

      return (
        this.bookingPolicies.find((policy) => policy.defaultPolicy) || null
      );
    },
  },
  created() {
    if (!this.bookingPolicies?.length) {
      this.fetchBookingPolicies();
    }
  },
  methods: {
    fetchBookingPolicies() {
      this.$store
        .dispatch("bookingPolicies/fetchPolicies", {
          pagination: false,
          sort: "asc",
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intenter carregar les polítiques de reserva"
          )
        );
    },
    onDeleteRateError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar eliminar la tarifa. Intenta-ho de nou o contacta amb en Pedro si el problem persisteix"
      );
    },
    onOwnerRatesAdded() {
      this.$bvModal.hide("add-rates-modal");
      this.$store.dispatch("contract/fetchOwnerRates", this.contract.uuid);
    },
    onAddRateError() {
      notifyError(
        "Error",
        "Hi ha hagut un error al intentar afegir la tarifa. Intenta-ho de nou o contacta amb en Pedro si el problem persisteix"
      );
    },
    getCurrentRange(currentRange) {
      return {
        startDate: currentRange.startDate,
        endDate: currentRange.endDate,
        price: currentRange.price,
        bookingPolicy: currentRange.bookingPolicy,
      };
    },
  },
};
</script>
