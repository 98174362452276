var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column justify-content-between",attrs:{"id":"contract-rates-form"}},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Data d'inici","label-for":"startDate"}},[_c('b-form-datepicker',{attrs:{"id":"startDate","placeholder":"Inici","locale":_vm.$i18n.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },"hide-header":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Data de fi","label-for":"endDate"}},[_c('b-form-datepicker',{ref:"endDateDatepicker",attrs:{"id":"endDate","placeholder":"Fi","min":_vm.startDate,"locale":_vm.$i18n.locale,"start-weekday":1,"show-decade-nav":false,"date-format-options":{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            },"hide-header":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Import propietari","label-for":"price"}},[_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"id":"price","type":"number","number":""},model:{value:(_vm.ownerPrice),callback:function ($$v) {_vm.ownerPrice=$$v},expression:"ownerPrice"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"Política de reserva","label-for":"bookingPolicy"}},[_c('v-select',{attrs:{"id":"bookingPolicy","options":_vm.bookingPoliciesOptions,"reduce":function (option) { return option.value; },"loading":_vm.loadingBookingPolicies},model:{value:(_vm.bookingPolicy),callback:function ($$v) {_vm.bookingPolicy=$$v},expression:"bookingPolicy"}})],1)],1)],1)],1),_c('b-row',{staticClass:"mb-1 d-flex justify-content-between justify-content-sm-end mt-2"},[_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"light"}},[_vm._v(" Cancel·la ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":_vm.save}},[_vm._v(" Guarda ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }