<template>
  <b-overlay :show="loadingContract" variant="white" :opacity="1">
    <b-card id="checkin-checkout-card" title="Entrades i Sortides">
      <b-row>
        <b-col cols="12" sm="6" md="4" lg="3">
          <h5>Entrada autònoma</h5>
          <b-form-checkbox v-if="editMode" v-model="selfCheckin" switch />
          <span v-else>{{ contractSelfCheckin }}</span>
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2 mt-sm-0">
          <h5>Responsable entrada</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="checkinResponsibleName" />
          </b-input-group>
          <span v-else>{{ contractCheckinResponsibleName }}</span>
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2 mt-md-0">
          <h5>Telf. responsable entrada</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="checkinResponsiblePhone" />
          </b-input-group>
          <span v-else>{{ contractCheckinResponsiblePhone }}</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
          <h5>Sortida autònoma</h5>
          <b-form-checkbox v-if="editMode" v-model="selfCheckout" switch />
          <span v-else>{{ contractSelfCheckout }}</span>
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
          <h5>Responsable sortida</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="checkoutResponsibleName" />
          </b-input-group>
          <span v-else>{{ contractCheckoutResponsibleName }}</span>
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
          <h5>Telf. responsable sortida</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="checkoutResponsiblePhone" />
          </b-input-group>
          <span v-else>{{ contractCheckoutResponsiblePhone }}</span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
          <h5>Ubicació de les claus</h5>
          <v-select
            v-if="editMode"
            v-model="keysLocation"
            placeholder="Selecciona una ubicació"
            :options="keysLocationOptions"
            :reduce="(option) => option.value"
          >
            <template #option="{ label, description }">
              <div>{{ label }}</div>
              <small v-if="description">{{ description }}</small>
            </template>

            <template #selected-option="{ label, description }">
              <div>
                {{ label }}
                <small v-if="description">({{ description }})</small>
              </div>
            </template>
          </v-select>
          <span v-else>{{ contractKeysLocation }}</span>
        </b-col>
        <b-col cols="12" sm="6" md="4" lg="3" class="mt-2">
          <h5>Codi Lockbox</h5>
          <b-input-group v-if="editMode">
            <b-form-input v-model="lockboxCode" />
          </b-input-group>
          <span v-else>{{ contractLockboxCode }}</span>
        </b-col>
        <b-col cols="12" class="mt-2">
          <h5>Comentaris adicionals</h5>
          <quill-editor
            v-if="editMode"
            v-model="checkinCheckoutComments"
            :options="editorOption"
          />
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            v-else
            class="bg-light p-1"
            v-html="contractCheckinCheckoutComments"
          />
        </b-col>
      </b-row>

      <!-- BUTTONS -->
      <b-row class="d-flex justify-content-between justify-content-sm-end mt-1">
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="light" @click="editMode = false">
            {{ $t("Cancel·la") }}
          </b-button>
        </b-col>
        <b-col v-if="editMode" cols="6" sm="auto">
          <b-button block variant="primary" @click="save">
            {{ $t("Guarda") }}
          </b-button>
        </b-col>
        <b-col v-if="!editMode" cols="12" sm="auto">
          <b-button block variant="primary" @click="editMode = true">
            {{ $t("Edita") }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  BButton,
  BFormInput,
  BInputGroup,
  BFormCheckbox,
} from "bootstrap-vue";
import { getKeysLocationName, notifyError } from "@/utils/methods";
import { keysLocationOptions } from "@/utils/select-options";
import vSelect from "vue-select";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import { quillEditor } from "vue-quill-editor";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BInputGroup,
    BFormCheckbox,
    vSelect,
    quillEditor,
  },
  data() {
    return {
      editMode: false,
      selfCheckin: false,
      checkinResponsibleName: null,
      checkinResponsiblePhone: null,
      selfCheckout: false,
      checkoutResponsibleName: null,
      checkoutResponsiblePhone: null,
      keysLocation: null,
      lockboxCode: null,
      checkinCheckoutComments: null,
      editorOption: {
        modules: {
          toolbar: [["bold", "italic", "underline"]],
        },
      },
    };
  },
  computed: {
    loadingContract() {
      return this.$store.getters["contract/loading"];
    },
    contract() {
      return this.$store.getters["contract/contract"];
    },
    contractSelfCheckin() {
      return this.contract?.selfCheckin ? "Sí" : "No";
    },
    contractCheckinResponsibleName() {
      return this.contract?.checkinResponsibleName || this.$t("No disponible");
    },
    contractCheckinResponsiblePhone() {
      return this.contract?.checkinResponsiblePhone || this.$t("No disponible");
    },
    contractSelfCheckout() {
      return this.contract?.selfCheckout ? "Sí" : "No";
    },
    contractCheckoutResponsibleName() {
      return this.contract?.checkoutResponsibleName || this.$t("No disponible");
    },
    contractCheckoutResponsiblePhone() {
      return (
        this.contract?.checkoutResponsiblePhone || this.$t("No disponible")
      );
    },
    contractKeysLocation() {
      return (
        getKeysLocationName(this.contract?.keysLocation, this.$i18n.locale) ||
        this.$t("No disponible")
      );
    },
    contractLockboxCode() {
      return this.contract?.lockboxCode || this.$t("No disponible");
    },
    contractCheckinCheckoutComments() {
      return (
        this.contract?.checkinCheckoutComments || this.$t("Sense comentaris")
      );
    },
    keysLocationOptions() {
      return keysLocationOptions.map((option) => {
        const regex = /(.*)\((.*)\)/;
        const match = option.label.match(regex);
        return {
          ...option,
          label: match ? match[1].trim() : option.label,
          description: match ? match[2].trim() : null,
        };
      });
    },
  },
  watch: {
    editMode(edit) {
      if (edit) this.initInputs();
      else this.resetInputs();
    },
  },
  methods: {
    initInputs() {
      this.selfCheckin = this.contract?.selfCheckin || false;
      this.checkinResponsibleName =
        this.contract?.checkinResponsibleName || null;
      this.checkinResponsiblePhone =
        this.contract?.checkinResponsiblePhone || null;
      this.selfCheckout = this.contract?.selfCheckout || false;
      this.checkoutResponsibleName =
        this.contract?.checkoutResponsibleName || null;
      this.checkoutResponsiblePhone =
        this.contract?.checkoutResponsiblePhone || null;
      this.keysLocation = this.contract?.keysLocation || null;
      this.lockboxCode = this.contract?.lockboxCode || null;
      this.checkinCheckoutComments =
        this.contract?.checkinCheckoutComments || null;
    },
    resetInputs() {
      this.selfCheckin = false;
      this.checkinResponsibleName = null;
      this.checkinResponsiblePhone = null;
      this.selfCheckout = false;
      this.checkoutResponsibleName = null;
      this.checkoutResponsiblePhone = null;
      this.keysLocation = null;
      this.lockboxCode = null;
      this.checkinCheckoutComments = null;
    },
    save() {
      this.$store.dispatch("app/setLoading", true);

      this.$store
        .dispatch("contract/updateContract", {
          uuid: this.contract.uuid,
          selfCheckin: this.selfCheckin,
          checkinResponsibleName: this.checkinResponsibleName || null,
          checkinResponsiblePhone: this.checkinResponsiblePhone || null,
          selfCheckout: this.selfCheckout,
          checkoutResponsibleName: this.checkoutResponsibleName || null,
          checkoutResponsiblePhone: this.checkoutResponsiblePhone || null,
          keysLocation: this.keysLocation,
          lockboxCode: this.lockboxCode,
          checkinCheckoutComments: this.checkinCheckoutComments || null,
        })
        .then(() => {
          this.editMode = false;
          // TODO: log the contract update?
        })
        .catch(() =>
          notifyError(
            "Error",
            "Hi ha hagut un error al intentar actualitzar el contracte"
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#checkin-checkout-card {
  .quill-editor {
    .ql-editor {
      p {
        margin-top: 0 !important;
        margin-bottom: 1rem !important;
      }
    }
  }
}
</style>
